import {
  basicGetRequest,
  basicPostRequest,
  generalGetRequest,
  vimeoAuth,
} from "../hooks/apiRequests";
import { CreateFormData } from "../hooks/createFormData";

export const fetchVideoDetails = async (payload) => {
  return await basicPostRequest(
    "video-details",
    payload,
    window.localStorage.token || ""
  );
};

export const saveVideoContent = async (payload) => {
  return await basicPostRequest(
    "save-video",
    payload,
    window.localStorage.token || ""
  );
};

export const saveVideoImage = async (payload) => {
  return await basicPostRequest(
    "save-image",
    CreateFormData(payload),
    window.localStorage.token || ""
  );
};

export const saveVideoBackgroundImage = async (payload) => {
  return await basicPostRequest(
    "update-video-bg",
    CreateFormData(payload),
    window.localStorage.token || ""
  );
};

export const fetchVimeoDetails = async (url) => {
  let array = url?.split("/") || [];
  let videoCode = array[array.length - 1];
  return await generalGetRequest(
    `https://api.vimeo.com/videos/${videoCode}`,
    vimeoAuth
  );
};

export const searchYoutubeVideo = async (query,key) => {
  
  return await generalGetRequest(
    `https://www.googleapis.com/youtube/v3/search?key=${key}&type=video&part=snippet&q=${query}&&maxResults=50`,
    ""
  );
};

export const getYoutubeDetails = async (videoID) => {
  return await generalGetRequest(
    `https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=${videoID}&format=json`,
    ""
  );
};
export const sendQuizContent = async (payload) => {
  return await basicPostRequest(
    "send-quiz",
    payload,
    window.localStorage.token || ""
  );
};