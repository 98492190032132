import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAdminDetails,
  fetchAdminStats,
  fetchAdminUsers,
  fetchChatGPTKey,
  fetchPlans,
  fetchTemplateDetails,
  fetchTemplates,
  fetchUserPlans,
  fetchUserVideos,
  fetchYoutubeKeys,
} from "../services/adminServices";
import { fetchVideoDetails } from "../services/videoServices";

export const getAdminDetails = createAsyncThunk(
  "admin/getAdminDetails",
  async () => {
    try {
      return await fetchAdminDetails().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

export const getPlans = createAsyncThunk("admin/getPlans", async () => {
  try {
    return await fetchPlans().then((response) => response.data);
  } catch (e) {
    return e?.response;
  }
});

export const getUserPlans = createAsyncThunk("admin/getUserPlans", async () => {
  try {
    return await fetchUserPlans().then((response) => response.data);
  } catch (e) {
    return e?.response;
  }
});

export const getUserVideos = createAsyncThunk(
  "admin/getUserVideos",
  async () => {
    try {
      return await fetchUserVideos().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

export const getTemplates = createAsyncThunk("admin/getTemplates", async () => {
  try {
    return await fetchTemplates().then((response) => response.data);
  } catch (e) {
    return e?.response;
  }
});

export const getTemplateDetails = createAsyncThunk(
  "admin/getTemplateDetails",
  async (payload) => {
    try {
      return await fetchTemplateDetails(payload).then(
        (response) => response.data
      );
    } catch (e) {
      return e?.response;
    }
  }
);

export const getAdminStat = createAsyncThunk("admin/getAdminStat", async () => {
  try {
    return await fetchAdminStats().then((response) => response.data);
  } catch (e) {
    return e?.response;
  }
});
export const getAdminUsers = createAsyncThunk(
  "admin/getAdminUsers",
  async () => {
    try {
      return await fetchAdminUsers().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

export const getChatGPTKeys = createAsyncThunk(
  "admin/getChatGPTKeys",
  async () => {
    try {
      return await fetchChatGPTKey().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

export const getYoutubeKeys = createAsyncThunk(
  "admin/getYoutubeKeys",
  async () => {
    try {
      return await fetchYoutubeKeys().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);
export const getAdminVideoDetails = createAsyncThunk(
  "admin/getAdminVideoDetails",
  async (payload) => {
    return await fetchVideoDetails(payload).then((response) => response.data);
  }
);

const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    token: "",
    admin: "",
    videos: "",
    templates: "",
    templateContainer: "",
    templateDetails: "",
    plans: "",
    userPlans: "",
    users: "",
    videoDetails: "",
    videoContainer: "",
    dashboardStat: "",
    chatgpt: "",
    youtubeKeys: "",
  },
  reducers: {
    updateUserToken: (state, actions) => {
      state.token = actions.payload?.token;
    },
    updateAdminUser: (state, action) => {
      let array = [...state.users];
      let index = array.findIndex((item) => item.id === action.payload.userid);
      // console.log(index,action.payload)
      if (index !== -1) {
        let oldData = { ...array[index] };
        action.payload.is_active = Boolean(action.payload.is_active);

        array.splice(index, 1, { ...oldData, ...action.payload });
      }

      state.users = array;
    },

    deleteAdminUser: (state, action) => {
      let array = [...state.users];
      let index = array.findIndex((item) => item.id === action.payload.delID);
      // console.log(index,action.payload)
      if (index !== -1) {
        array.splice(index, 1);
      }

      state.users = array;
    },

    updateUserPlan: (state, action) => {
      let array = [...state.userPlans];
      let index = array.findIndex((item) => item.id === action.payload.id);
      console.log(index, action.payload);

      if (index !== -1) {
        let oldData = { ...array[index] };
        array.splice(index, 1, { ...oldData, ...action.payload });
      }

      state.userPlans = array;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAdminDetails.fulfilled, (state, action) => {
      if (action.payload.status) state.admin = action.payload.details;
    });

    builder.addCase(getPlans.fulfilled, (state, action) => {
      if (action.payload.status) state.plans = action.payload.data;
    });
    builder.addCase(getUserPlans.fulfilled, (state, action) => {
      if (action.payload.status) state.userPlans = action.payload.data;
    });

    builder.addCase(getUserVideos.fulfilled, (state, action) => {
      if (action.payload.status) state.videos = action.payload.data;
    });

    builder.addCase(getAdminStat.fulfilled, (state, action) => {
      if (action.payload.status) state.dashboardStat = action.payload.data;
    });
    builder.addCase(getAdminUsers.fulfilled, (state, action) => {
      if (action.payload.status) state.users = action.payload.data;
    });
    builder.addCase(getChatGPTKeys.fulfilled, (state, action) => {
      if (action.payload.status) state.chatgpt = action.payload.details;
    });
    builder.addCase(getYoutubeKeys.fulfilled, (state, action) => {
      if (action.payload.status) state.youtubeKeys = action.payload.data;
    });
    builder.addCase(getAdminVideoDetails.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.videoDetails = {
          ...action.payload.details,
          payment: action.payload.payment,
        };
        // console.log(state.videoContainer.length);
        if (
          // state.videoContainer.length === 0 &&
          action.payload.details?.content &&
          action.payload.details?.content !== "null"
        ) {
          console.log("yes o ");
          state.videoContainer = JSON.parse(action.payload.details?.content);
          // console.log(state.videoContainer);
        }
      }
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      if (action.payload.status) state.templates = action.payload.data;
    });
    builder.addCase(getTemplateDetails.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.templateDetails = {
          ...action.payload.details,
          payment: action.payload.payment,
        };
        if (
          action.payload.details?.content &&
          action.payload.details?.content !== "null"
        ) {
          state.templateContainer = JSON.parse(action.payload.details?.content);
        }
      }
    });
  },
});

const { reducer } = AdminSlice;
export const {
  updateUserToken,
  updateAdminUser,
  updateUserPlan,
  deleteAdminUser,
} = AdminSlice.actions;

export default reducer;
