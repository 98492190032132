import {createSlice} from "@reduxjs/toolkit";


const authSlice = createSlice({
    name: 'authSlice',
    initialState: {},
    reducers: {},
    extraReducers: {}
})


const { reducer } = authSlice;

export default reducer;


