// import { LazyRouteHandler } from "../hooks/lazyRouteHandler";
// import { useRoutes } from "react-router";
// import { AdminRouteHandler } from "./adminRoutes";
import loadable from "@loadable/component";
 
import { createBrowserRouter,useRouteError } from "react-router-dom";
import { AdminRoutes } from "./newAdminRoutes.jsx";
const Index = loadable(() => import("../views/index.jsx"))
const Login= loadable(() => import("../views/authorization/login.jsx"))
const Register= loadable(() => import("../views/authorization/register.jsx"))
const ResetPassword= loadable(() => import("../views/authorization/resetPassword.jsx"))
const Dashboard= loadable(() => import("../views/dashboard/dashboard.jsx"))
const CreateVideo= loadable(() => import("../views/create/createVideo.jsx"))
const ManageVideo= loadable(() => import("../views/manage/manageVideo.jsx"))
const App= loadable(() => import("../views/app.jsx"))
const Settings= loadable(() => import("../views/settings/settings.jsx"))
const Agency= loadable(() => import("../views/agency/agency.jsx"))
const Automation= loadable(() => import("../views/automation/automation.jsx"))
const MultipleIncome= loadable(() => import("../views/income/multiple-income.jsx"))
const Reseller= loadable(() => import("../views/reseller/reseller.jsx"))
const SwiftProfit= loadable(() => import("../views/swiftprofit/swiftprofit.jsx"))
const Traffic= loadable(() => import("../views/traffic/traffic.jsx"))
const Tutorial= loadable(() => import("../views/tutorial/tutorial.jsx"))
const Dfy= loadable(() => import("../views/dfy/dfy.jsx"))
const MyOffers= loadable(() => import("../views/dfy/offers.jsx"))
const Plans= loadable(() => import("../views/plans/oto.jsx"))
const Editor= loadable(() => import("../views/editor/editor.jsx"))
const Preview= loadable(() => import("../views/preview/preview.jsx"))
const Video= loadable(() => import("../views/preview/preview.jsx"))
const StripeHandler= loadable(() => import("../views/payments/stripeHandler.jsx"))
const ConfirmStripe= loadable(() => import("../views/payments/confirmStripe.jsx"))
const ErrorPage= loadable(() => import("../views/unauthorized/error.jsx"))
const NotFound= loadable(() => import("../views/unauthorized/notfound.jsx"))
const Aweber= loadable(() => import("../views/autoresponder/aweber.jsx"))
 


export const CustomError = () => {
  const error = useRouteError();
  console.log(error)
  return (
      <ErrorPage/>

  );
};

export const directPath = `../views/`;
export const routes = createBrowserRouter([
  {
    path: "/",
    name: "index",

    element: <Index/>,
    errorElement: <CustomError/>,

    children: [
        //admin routes
        // ...AdminRouteHandler,
        ...AdminRoutes,
        {
          path: "login",
          
          element: <Login/>,
        },
        {
          path: "8jzqvrrtwfpyrtfhthxw",
          
          element: <Register/>,
        },
        {
          path: "/reset-password/:str/:userid/:str",
          
          element: <ResetPassword/>,
        },
        {
          path: "app/",
  
          element: <App/>,
          children: [
            {
              path: "dashboard",
              element: <Dashboard/>,
            },
            {
              path: "create-video",
              element: <CreateVideo/>,
            },
            {
              path: "manage-video",
              element: <ManageVideo/>,
            },
            {
              path: "settings",
              element: <Settings/>,
            },
            {
              path: "agency",
              element: <Agency/>,
            },
            {
              path: "automation",
              element: <Automation/>,
            },
            // {
            //   path: "bonus",
            //   element: LazyRouteHandler(`${directPath}bonus/bonus`),
            // },
            {
              path: "multiple-income",
              element: <MultipleIncome/>,
            },
            {
              path: "reseller",
              element: <Reseller/>,
            },
            {
              path: "swift-profit",
              element: <SwiftProfit/>,
            },
            {
              path: "traffic",
              element: <Traffic/>,
            },
            {
              path: "tutorial",
              element: <Tutorial/>,
            },
            {
              path: "done-for-you",
              element: <Dfy/>,
            },
            {
              path: "offers",
              element: <MyOffers/>
            },
            {
              path: "plans",
              element: <Plans/>,
            },
          ],
        },
      ],
    },
    {
      path: "/editor/:id/:token",
      name: "editor",
      element: <Editor/>,
    },
    {
      path: "/preview-video/:id/:token",
      name: "preview",
      element: <Preview/>,
    },
    {
      path: "/vid/:slug/:id",
      name: "video",
      element: <Video/>,
    },
    {
      path: "/stripe-payment/:token/:id/:token/:total",
      name: "stripe",
      element: <StripeHandler/>,
    },
    {
      path: "/confirm-stripe-payment/:token/:id/:token",
      name: "confirmStripe",
      element: <ConfirmStripe/>,
    },
    {
      path: "/aweber-autoresponder",
      name: "Aweber",
      element: <Aweber/>,
    },
    {
      path: '*',
      element: <NotFound/>
  }


  


  

   
])
