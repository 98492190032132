import { useState } from "react";
import { RouterProvider,Outlet } from "react-router";

import "./App.css";
import "./assets/css/w3.css";
import "./assets/css/checkout.css";
// import { RouteHandler } from "./routes/routes";
import { routes } from "./routes/newRoutes";

function App() {
  return (
    <>
      {/* welocome */}
      {/* <RouteHandler/> */}

      <RouterProvider router={routes} />
      <Outlet />
    </>
  );
}

export default App;
