
import { LazyRouteHandler } from "../hooks/lazyRouteHandler"
import loadable from "@loadable/component";
// import { directPath } from "./routes"

const Login = loadable(() => import("../views/admin/auth/login.jsx"))
const Account = loadable(() => import("../views/admin/index.jsx"))
const Dashboard = loadable(() => import("../views/admin/dashboard/dashboard"))
const Users = loadable(() => import("../views/admin/users/users"))
const Plans = loadable(() => import("../views/admin/plans/plans"))
const Videos = loadable(() => import("../views/admin/videos/videos"))
const PreviewVideo = loadable(() => import("../views/admin/videos/preview-video"))
const Settings = loadable(() => import("../views/admin/settings/settings"))
const DFY = loadable(() => import("../views/admin/dfy/dfy"))
const YoutubeApi = loadable(() => import("../views/admin/youtube/youtube-api"))
const CreateVideo = loadable(() => import("../views/admin/template/createVideo"))

const adminPath= `../views/admin/`
export  const AdminRoutes = [
    {
        path:'admin/login',
        element:<Login/>
    },
    {
        path: "admin/editor/:id/:token",
        name: "editor",
        element: LazyRouteHandler(`${adminPath}editor/editor`),
      },
  
    
    {
        path:'admin/account/',
         
        element:<Account/>,
        children:[
            {
                path:'dashboard',
                element:<Dashboard/>
            },
            {
                path:'users',
                element:<Users/>
            },
           
           
            {
                path:'plans',
                element:<Plans/>
            },
            {
                path:'campaigns',
                element:<Videos/>
            },
            {
                path:'videos-details/:id/:token',
                element:<PreviewVideo/>
            },
            
            {
                path:'settings',
                element:<Settings/>
            },
            {
                path:'dfy',
                element:<DFY/>
            },
            {
                path:'youtube-api',
                element:<YoutubeApi/>
            },
            {
                path:'template-creator',
                element:<CreateVideo/>
            },
        ]
    },
    
]