import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchAutomationList,
  fetchPlans,
  fetchUserAgency,
  fetchUserAutoResponders,
  fetchUserDetails,
  fetchUserPayment,
  fetchUserPlans,
  fetchUserStat,
  fetchUserVideos,
  fetchYouTubeKey,
} from "../services/userServices";
import { fetchTemplates } from "../services/adminServices";
import { fetchPixaBay } from "../hooks/apiRequests";

export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async () => {
    try {
      return await fetchUserDetails().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

export const getUserVideos = createAsyncThunk(
  "user/getUserVideos",
  async () => {
    try {
      return await fetchUserVideos().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

export const getUserPaymentSettings = createAsyncThunk(
  "user/getUserPaymentSettings",
  async () => {
    try {
      return await fetchUserPayment().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

export const getUserPlans = createAsyncThunk("user/getUserPlans", async () => {
  try {
    return await fetchUserPlans().then((response) => response.data);
  } catch (e) {
    return e?.response;
  }
});

export const getPlans = createAsyncThunk("user/getPlans", async () => {
  try {
    return await fetchPlans().then((response) => response.data);
  } catch (e) {
    return e?.response;
  }
});

export const getUserStats = createAsyncThunk("user/getUserStats", async () => {
  try {
    return await fetchUserStat().then((response) => response.data);
  } catch (e) {
    return e?.response;
  }
});

export const getUserTemplates = createAsyncThunk(
  "user/getUserTemplates",
  async () => {
    try {
      return await fetchTemplates().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

export const getUserAgency = createAsyncThunk(
  "user/getUserAgency",
  async () => {
    try {
      return await fetchUserAgency().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

export const getPixaBay = createAsyncThunk("user/getPixaBay", async (url) => {
  try {
    return fetchPixaBay(url).then((response) => response.data || {});
  } catch (e) {
    // @ts-ignore
    return e?.response;
  }
});

export const getUserAutoResponders = createAsyncThunk(
  "user/getUserAutoResponders",
  async () => {
    try {
      return await fetchUserAutoResponders().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

export const getUserYoutubeKey = createAsyncThunk(
  "user/getUserYoutubeKey",
  async () => {
    try {
      return await fetchYouTubeKey().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

export const getUserAutomation = createAsyncThunk(
  "user/getUserAutomation",
  async () => {
    try {
      return await fetchAutomationList().then((response) => response.data);
    } catch (e) {
      return e?.response;
    }
  }
);

const UserSlice = createSlice({
  name: "user",
  initialState: {
    token: "",
    user: "",
    videos: "",
    payment: { paystack: "", stripe: "" },
    userPlans: "",
    planList: [],
    dashboardStat: "",
    templates: "",
    agency: "",
    stockMedia: [],
    autoResponders: [],
    youTubeKey:'',
    automation:''
  },
  reducers: {
    updateUserToken: (state, actions) => {
      state.token = actions.payload?.token;
    },
    updateStockMedia: (state, action) => {
      state.stockMedia = [];
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      if (action.payload.status) state.user = action.payload.details;
    });
    builder.addCase(getUserVideos.fulfilled, (state, action) => {
      if (action.payload.status) state.videos = action.payload.data;
      else state.videos = [];
    });
    builder.addCase(getUserPaymentSettings.fulfilled, (state, action) => {
      if (action.payload.status) state.payment = action.payload.details;
      else state.payment = { paystack: "", stripe: "" };
    });

    builder.addCase(getUserPlans.fulfilled, (state, action) => {
      if (action.payload.status) state.userPlans = action.payload.data;
      else state.userPlans = "";
    });

    builder.addCase(getPlans.fulfilled, (state, action) => {
      if (action.payload.status) state.planList = action.payload.data;
    });

    builder.addCase(getUserStats.fulfilled, (state, action) => {
      if (action.payload.status) state.dashboardStat = action.payload.data;
    });
    builder.addCase(getUserTemplates.fulfilled, (state, action) => {
      if (action.payload.status) state.templates = action.payload.data;
    });

    builder.addCase(getUserAgency.fulfilled, (state, action) => {
      if (action.payload.status) state.agency = action.payload.data;
    });
    builder.addCase(getPixaBay.fulfilled, (state, action) => {
      state.stockMedia = action.payload.hits;
    });
    builder.addCase(getUserAutoResponders.fulfilled, (state, action) => {
      state.autoResponders = action.payload.data;
    });
    builder.addCase(getUserYoutubeKey.fulfilled, (state, action) => {
      state.youTubeKey = action.payload.data.key;
    });
    builder.addCase(getUserAutomation.fulfilled, (state, action) => {
      state.automation = action.payload.data;
    });
  },
});
const { reducer } = UserSlice;
export const { updateUserToken, updateStockMedia } = UserSlice.actions;

export default reducer;
