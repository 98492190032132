import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice.js";
import undoable, {ActionTypes} from "redux-undo";
import videoReducer from "./videoSlice.js";
import userReducer from "./userSlice.js";
import adminReducer from "./adminSlice.js";
const persistConfig = {
  key: "root",
  storage: storage,
}

const reducers = combineReducers({
  auth: authReducer,
  video: undoable(videoReducer, {
    limit: 5,
    ignoreInitialState: false,
    syncFilter: true,
    neverSkipReducer: true,
    debug:false


  }),
  user: userReducer,
  admin: adminReducer,
});

const _persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);
