import axios from "axios";
import ToastMessage from "./toastMessage";

export const appURL = `https://myvaiapp.com:446`
export const baseURL = `https://myvaiapp.com:446/api/`;


// export const baseURL = `http://127.0.0.1:8000/api/`;

// export const appURL = `http://127.0.0.1:8000`;


export const vimeoAuth = `b228719b6fc4fa6c370464fccc5c3ba8`;

export const subscribeUsers = async (url, payload) => {
  return await axios.post(url, payload);
};

export const generalGetRequest = async (url, token) => {
  try {
    return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    console.log(e);
    return ToastMessage("error", e.message);
  }
};

export const basicPostRequest = async (endpoint, payload, token = "") => {
  try {
    return await axios.post(`${baseURL}${endpoint}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    console.log(e);
    return ToastMessage("error", e.message);
  }
};

export const basicGetRequest = async (endpoint, payload, token = "") => {
  try {
    return await axios.get(
      `${baseURL}${endpoint}${payload ? "/" + payload : ""}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    console.log(e);
    return ToastMessage("error", e.message);
  }
};

export const fetchPixaBay = async (url) => {
  try {
    return await axios.get(url);
  } catch (e) {
    console.log(e);
    return ToastMessage("error", e.message);
  }
};

 