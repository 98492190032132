import { basicGetRequest, basicPostRequest } from "../hooks/apiRequests";
import { CreateFormData } from "../hooks/createFormData";

export const loginUser = async (payload) => {
  return await basicPostRequest("login", payload, "");
};

export const createVideo = async (payload) => {
  return await basicPostRequest(
    "create-video",
    CreateFormData(payload),
    window.localStorage.token || ""
  );
};

export const fetchUserDetails = async () => {
  return await basicGetRequest(
    "user-details",
    "",
    window.localStorage.token || ""
  );
};

export const fetchUserVideos = async () => {
  return await basicGetRequest(
    "user-videos",
    "",
    window.localStorage.token || ""
  );
};

export const deleteUserVideo = async (payload) => {
  return await basicPostRequest(
    "delete-video",
    payload,
    window.localStorage.token || ""
  );
};

export const fetchUserPayment = async () => {
  return await basicGetRequest(
    "payment-settings",
    "",
    window.localStorage.token || ""
  );
};

export const updatePaymentSettings = async (payload) => {
  return await basicPostRequest(
    "update-payment",
    payload,
    window.localStorage.token || ""
  );
};

export const updatePassword = async (payload) => {
  return await basicPostRequest(
    "update-password",
    payload,
    window.localStorage.token || ""
  );
};

export const changeUserPassword = async (payload) => {
  return await basicPostRequest(
    "change-password",
    payload,
    window.localStorage.token || ""
  );
};

export const updateProfile = async (payload) => {
  let { pathname } = window.location;
  return await basicPostRequest(
    "update-profile",
    payload,
    pathname.includes("/admin")
      ? window.localStorage.adminToken
      : window.localStorage.token || ""
  );
};

export const fetchUserPlans = async () => {
  return await basicGetRequest(
    "user-plan",
    "",
    window.localStorage.token || ""
  );
};

export const fetchPlans = async () => {
  return await basicGetRequest("plans", "", window.localStorage.token || "");
};

export const fetchUserStat = async () => {
  return await basicGetRequest(
    "dashboard-stat",
    "",
    window.localStorage.token || ""
  );
};

export const logoutUser = async () => {
  return await basicPostRequest(
    "user-logout",
    {},
    window.localStorage.token || ""
  );
};

export const copyTemplate = async (payload) => {
  return await basicPostRequest(
    "copy-template",
    payload,
    window.localStorage.token || ""
  );
};

export const addAgencyUser = async (payload) => {
  return await basicPostRequest(
    "agency-add-user",
    payload,
    window.localStorage.token || ""
  );
};

export const editAgencyUser = async (payload) => {
  return await basicPostRequest(
    "agency-edit-user",
    payload,
    window.localStorage.token || ""
  );
};

export const fetchUserAgency = async () => {
  return await basicGetRequest(
    "user-agency",
    "",
    window.localStorage.token || ""
  );
};

export const generateAIVideoContent = async (payload) => {
  return await basicPostRequest(
    "generate-videocontent",
    payload,
    window.localStorage.token || ""
  );
};

export const generateSpecificAIVideoContent = async (payload) => {
  return await basicPostRequest(
    "generate-specific-videocontent",
    payload,
    window.localStorage.token || ""
  );
};

export const saveAIContent = async (payload) => {
  return await basicPostRequest(
    "save-aicontent",
    payload,
    window.localStorage.token || ""
  );
};

export const resetUserPassword = async (payload) => {
  return await basicPostRequest(
    "reset-password",
    payload,
    window.localStorage.token || ""
  );
};

export const getAweberToken = async (payload) => {
  return await basicPostRequest(
    "get_aweber_token",
    payload,
    window.localStorage.token || ""
  );
};
export const authorizeAweberToken = async (payload) => {
  return await basicPostRequest(
    "aweber-authorize",
    payload,
    window.localStorage.token || ""
  );
};

export const fetchUserAutoResponders = async () => {
  return await basicGetRequest(
    "user-autoresponders",
    "",
    window.localStorage.token || ""
  );
};

export const addGetResponseKey = async (payload) => {
  return await basicPostRequest(
    "add-getresponse",
    payload,
    window.localStorage.token || ""
  );
};

export const addMailChimpKey = async (payload) => {
  return await basicPostRequest(
    "add-mailchimp",
    payload,
    window.localStorage.token || ""
  );
};

export const fetchAweberSubscriptionList = async () => {
  return await basicGetRequest(
    "get-aweberlist",
    "",
    window.localStorage.token || ""
  );
};

export const fetchMailChimpList = async () => {
  return await basicGetRequest(
    "get-mailchimplist",
    "",
    window.localStorage.token || ""
  );
};

export const subscribeToAweber = async (payload) => {
  return await basicPostRequest(
    "subscribe-aweber",
    payload,
    window.localStorage.token || ""
  );
};

export const subscribeToGetResponse = async (payload) => {
  return await basicPostRequest(
    "subscribe-getresponse",
    payload,
    window.localStorage.token || ""
  );
};


export const subscribeMailChimp = async (payload) => {
  return await basicPostRequest(
    "subscribe-mailchimp",
    payload,
    window.localStorage.token || ""
  );
};


export const fetchGRCampaign = async () => {
  return await basicGetRequest(
    "getresponse-list",
    "",
    window.localStorage.token || ""
  );
};

export const fetchMcCampaign = async () => {
  return await basicGetRequest(
    "get-mailchimplist",
    "",
    window.localStorage.token || ""
  );
};

export const fetchYouTubeKey = async () => {
  return await basicGetRequest(
    "get-youtubekey",
    "",
    window.localStorage.token || ""
  );
};

export const fetchAutomationList = async () => {
  return await basicGetRequest(
    "get-automation",
    "",
    window.localStorage.token || ""
  );
};


export const sendAutomationMail = async (payload) => {
  return await basicPostRequest(
    "send-automation-mail",
    payload,
    window.localStorage.token || ""
  );
};

export const subscribeAutomation = async (payload) => {
  return await basicPostRequest(
    "submit-automation",
    payload,
    window.localStorage.token || ""
  );
};

export const removeAutoResponder = async (payload) => {
  return await basicPostRequest(
    "remove-autoresponder",
    payload,
    window.localStorage.token || ""
  );
};






