import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { fetchVideoDetails } from "../services/videoServices";

export const getVideoDetails = createAsyncThunk(
  "videoSlice/getVideoDetails",
  async (payload) => {
    return await fetchVideoDetails(payload).then((response) => response.data);
  }
);
const videoSlice = createSlice({
  name: "videoSlice",
  initialState: {
    video: {
      src: "",
      type: "",
    },
    videoContainer: [],
    activeElement: {},
    videoDetails: "",
    videoDuration: 0,
    videoPlaying: false,
    videoCurrentTime: 0,
    videoVolume: 1,
    buttonLink: {
      content: "",
      link: "",
      color: "#000000",
      display: false,
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVideoDetails.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.videoDetails = {
          ...action.payload.details,
          payment: action.payload.payment,
        };
        if (
          action.payload.details?.content &&
          action.payload.details?.content !== "null"
        ) {
          // console.log("yes o ");
          state.videoContainer = JSON.parse(action.payload.details?.content);
          console.log(state.videoContainer);
        }

        if (action.payload.details.buttonLink) {
          state.buttonLink = JSON.parse(action.payload.details?.buttonLink);
        } else {
          state.buttonLink = {
            content: "",
            link: "",
            color: "#000000",
            display: false,
          };
        }
      }
    });
  },
  reducers: {
    updateVideo: (state, action) => {
      state.video = action?.payload;
    },
    updateVideoCurrentTime: (state, action) => {
      state.videoCurrentTime = action.payload;
    },
    updateVideoVolume: (state, action) => {
      state.videoVolume = action.payload;
    },
    updateVideoContainer: (state, action) => {
      // console.log(action.payload, 'payload')
      state.videoContainer.push(action?.payload);
    },

    deleteElement: (state, action) => {
      let array = [...state.videoContainer];
      let index = array.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        array.splice(index, 1);
        // console.log(array[0].left, array[0].top)
        if (state.activeElement.id === action.payload.id) {
          state.activeElement = {};
        }
      }
      state.videoContainer = array;
    },

    resetVideoContainer: (state, action) => {
      state.videoContainer = [];
    },

    deleteVideoElement: (state, action) => {
      state.videoContainer = [...action.payload];
    },

    setActiveElement: (state, action) => {
      state.activeElement = action.payload;
    },

    updateImageContent: (state, action) => {
      let videoContainer = [...state.videoContainer];

      console.log(action.payload)

      let index = videoContainer.findIndex(
        (item) => item.id === action.payload?.id
      );
      console.log(index,action.payload)

      if (index !== -1) {
        videoContainer[index].content.src = action.payload.image;
        state.videoContainer = videoContainer;
      }
    },
    toggleElementDisplay: (state, action) => {
      let array = [...state.videoContainer];
      let index = array.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        array[index].style.display =
          action.payload.display === "block" ? "none" : "block";
      }
      state.videoContainer = array;
    },
    resetActiveElement: (state, action) => {
      state.activeElement = {};
    },
    updateActiveElementContent: (state, action) => {
      let array = [...state.videoContainer];
      console.log(action.payload);

      let index = array.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        // console.log(action.payload)
        if (
          action.payload.index !== "" &&
          action.payload.topKey &&
          action.payload.bottomKey === "" &&
          action.payload.bottomIndex === ""
        ) {
          console.log("yes 1");
          array[index][action.payload.mode][action.payload.topKey][
            action.payload.index
          ][action.payload.key] = action.payload.value;
        } else if (
          action.payload.topKey &&
          action.payload.index === "" &&
          action.payload.bottomKey === ""
        ) {
          console.log("yes 2");
          array[index][action.payload.mode][action.payload.topKey][
            action.payload.key
          ] = action.payload.value;
        } else if (
          action.payload.index !== "" &&
          action.payload.topKey &&
          action.payload.bottomKey !== "" &&
          action.payload.bottomIndex!==''
        ) {
          console.log("yes 3");
          array[index][action.payload.mode][action.payload.topKey][
            action.payload.index
          ][action.payload.bottomKey][action.payload.bottomIndex][
            action.payload.key
          ] = action.payload.value;
        } else {
          console.log("yes 4");
          array[index][action.payload.mode][action.payload.key] =
            action.payload.value;
        }
      }
      state.videoContainer = array;
    },
    updateElementProps: (state, action) => {
      let array = [...state.videoContainer];
      let index = array.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        let value = "";
        if (action.payload.key === "transitionDuration") {
          value = parseFloat(action.payload.value) * 1000;
        } else if (action.payload.key === "transitionDelay") {
          value = parseFloat(action.payload.value);
        } else {
          value = action.payload.value;
        }

        array[index][action.payload.key] = value;
      }
      state.videoContainer = array;
    },
    addNewProduct: (state, action) => {
      let array = [...state.videoContainer];
      let index = array.findIndex((item) => item.id === action.payload.id);
      let lastItem = _.cloneDeep(
        array[index]["content"]["products"][
          array[index]["content"]["products"].length - 1
        ]
      );

      array[index]["content"]["products"] = [
        ...array[index]["content"]["products"],
        {
          ...lastItem,
        },
      ];

      state.videoContainer = array;
    },

    deleteProduct: (state, action) => {
      let array = [...state.videoContainer];
      let index = array.findIndex((item) => item.id === action.payload.id);
      array[index]["content"]["products"].splice(action.payload.index, 1);
      state.videoContainer = array;
    },

    addNewQuestion: (state, action) => {
      let array = [...state.videoContainer];
      let index = array.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        let question = {
          question: "Your Favorite set of Question",
          selectedAnswer: "",
          correctAnswer: "A",
          options: [
            { title: "Answer A", value: "A", key: "option_a" },
            { title: "Answer B", value: "B", key: "option_b" },
            { title: "Answer C", value: "C", key: "option_c" },
            { title: "Answer D", value: "D", key: "option_d" },
          ],
        };
        array[index]["content"]["options"] = [
          ...array[index]["content"]["options"],
          { ...question },
        ];
        state.videoContainer = array;
      }
    },
    deleteQuestion: (state, action) => {
      let array = [...state.videoContainer];
      let index = array.findIndex((item) => item.id === action.payload.id);
      array[index]["content"]["options"].splice(action.payload.index, 1);
      state.videoContainer = array;
    },

    clearVideoDetails: (state, action) => {
      state.videoDetails = "";
    },

    updateElementPosition: (state, action) => {
      let array = [...state.videoContainer];
      let index = array.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        array[index].left = action.payload.left;
        array[index].top = action.payload.top;
      }

      state.videoContainer = array;
    },

    updateElementDimension: (state, action) => {
      let array = [...state.videoContainer];
      // console.log(action.payload);
      let index = array.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        // console.log(array[index])
        array[index].width = action.payload.width;
        array[index].height = action.payload.height;
        array[index].left = action.payload.x;
        array[index].top = action.payload.y;
      }

      state.videoContainer = array;
    },
    updateVideoDuration: (state, action) => {
      state.videoDuration = parseFloat(action.payload.duration.toFixed(4));
    },
    updateVideoPlaying: (state, action) => {
      state.videoPlaying = action.payload;
    },
    updateVideoAIContent: (state, action) => {
      if (action.payload.type === "specific") {
        state.videoDetails[action.payload.key] = action.payload.value;
      } else {
        state.videoDetails.headline = action.payload.headline;
        state.videoDetails.description = action.payload.description;
      }
    },
    updateButtonLink: (state, action) => {
      state.buttonLink[action.payload.name] = action.payload.value;
    },
    updateVideoBgColor: (state, action) => {

      state.videoDetails[action.payload.name] = action.payload.value;
    },
  },
});

const { reducer } = videoSlice;

export const {
  updateVideo,
  updateVideoContainer,
  deleteVideoElement,
  setActiveElement,
  updateImageContent,
  toggleElementDisplay,
  resetActiveElement,
  updateActiveElementContent,
  addNewProduct,
  deleteProduct,
  clearVideoDetails,
  updateElementPosition,
  updateElementDimension,
  updateElementProps,
  updateVideoDuration,
  updateVideoPlaying,
  updateVideoCurrentTime,
  updateVideoVolume,
  resetVideoContainer,
  deleteElement,
  addNewQuestion,
  deleteQuestion,
  updateVideoAIContent,
  updateButtonLink,
  updateVideoBgColor
} = videoSlice.actions;

export default reducer;
