import { basicGetRequest, basicPostRequest } from "../hooks/apiRequests";
import { CreateFormData } from "../hooks/createFormData";

export const fetchAdminDetails = async () => {
  return await basicGetRequest(
    "user-details",
    "",
    window.localStorage.adminToken || ""
  );
};

export const fetchPlans = async () => {
  return await basicGetRequest(
    "admin/plans",
    "",
    window.localStorage.adminToken || ""
  );
};

export const createPlan = async (payload) => {
  return await basicPostRequest(
    "admin/add-plan",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const removePlan = async (payload) => {
  return await basicPostRequest(
    "admin/delete-plan",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const updateUser = async (payload) => {
  return await basicPostRequest(
    "admin/edit-user",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const createUser = async (payload) => {
  return await basicPostRequest(
    "admin/add-user",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const delUser = async (payload) => {
  return await basicPostRequest(
    "admin/delete-user",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const editUser = async (payload) => {
  return await basicPostRequest(
    "admin/edit-user",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const editPlan = async (payload) => {
  return await basicPostRequest(
    "admin/change-plan",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const fetchUserVideos = async () => {
  return await basicGetRequest(
    "admin/videos",
    "",
    window.localStorage.adminToken || ""
  );
};

export const adminLogin = async (payload) => {
  return await basicPostRequest(
    "admin/login",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const fetchAdminStats = async () => {
  return await basicGetRequest(
    "admin/dashboard-stat",
    "",
    window.localStorage.adminToken || ""
  );
};

export const fetchAdminUsers = async () => {
  return await basicGetRequest(
    "admin/users",
    "",
    window.localStorage.adminToken || ""
  );
};

export const fetchUserPlans = async () => {
  return await basicGetRequest(
    "admin/user-plans",
    "",
    window.localStorage.adminToken || ""
  );
};

export const fetchChatGPTKey = async () => {
  return await basicGetRequest(
    "admin/chatgpt",
    "",
    window.localStorage.adminToken || ""
  );
};

export const changeAPIKey = async (payload) => {
  return await basicPostRequest(
    "admin/update-chatgpt",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const fetchYoutubeKeys = async () => {
  return await basicGetRequest(
    "admin/youtube-keys",
    "",
    window.localStorage.adminToken || ""
  );
};

export const deleteYoutubeKeys = async (payload) => {
  return await basicPostRequest(
    "admin/delete-youtubekeys",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const addYoutubeKeys = async (payload) => {
  return await basicPostRequest(
    "admin/add-youtubekeys",
    payload,
    window.localStorage.adminToken || ""
  );
};


export const deleteUserVideos = async (payload) => {
  return await basicPostRequest(
    "admin/delete-videos",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const fetchUserVideoDetails = async (payload) => {
  return await basicPostRequest(
    "admin/video-details",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const fetchTemplates = async () => {
  return await basicGetRequest(
    "admin/template-videos",
    '',
    window.localStorage.adminToken || ""
  );
};

export const deleteTemplates = async (payload) => {
  return await basicPostRequest(
    "admin/delete-template",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const fetchTemplateDetails = async (payload) => {
  return await basicPostRequest(
    "admin/template-details",
    payload,
    window.localStorage.adminToken || ""
  );
};

export const createTemplate = async (payload) => {
  return await basicPostRequest(
    "admin/create-template",
    CreateFormData(payload),
    window.localStorage.adminToken || ""
  );
};