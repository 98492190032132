
import  {lazy, Suspense} from 'react';
import FallBack from '../components/fallback'; 

/**
 * Lazily load the mentioned component which resides in the page directory
 * This method will be used in routes so that the files are loaded only
 * When users are on that route
 */

export function LazyRouteHandler(componentName) {
    // const LazyElement = lazy(() => import(`${__dirname}/${componentName}.jsx`));
    const LazyElement = lazy(() => import(componentName))

    // Wrapping around the suspense component is mandatory
    return (
        <Suspense  fallback={<FallBack/>}>
            <LazyElement/>
        </Suspense>
    );
}