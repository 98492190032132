
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import {createTheme, ThemeProvider} from "@mui/material";
import {persistor, store} from "./store/store.js";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
// import 'regenerator-runtime' 
import 'react-toastify/dist/ReactToastify.css';
import { PayPalScriptProvider,  } from "@paypal/react-paypal-js";
const theme = createTheme({
    palette: {
        primary: {
            main: 'rgba(15, 23, 42, 1)',

        },
    },

})
const initialOptions = {
    clientId: "test",
    currency: "USD",
    intent: "capture",
};


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    {/* <PayPalScriptProvider options={initialOptions}> */}
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <ToastContainer />
              <ThemeProvider theme={theme}>
                  {/* <BrowserRouter> */}
                  
                      <App/>
                  {/* </BrowserRouter> */}


              </ThemeProvider>

          </PersistGate>

      </Provider>
      {/* </PayPalScriptProvider> */}
  </React.StrictMode>,
)
